<template>
  <div>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-5 pt-5">
        <v-row>
          <v-col sm="12" md="12" lg="6" class="subtitle-1 secondary--text pa-5 pl-8 pb-12">
            Change Account Password
          </v-col>
        </v-row>
        <v-form ref="changePasswordForm">
          <v-row>
            <v-col sm="12" md="12" lg="9" class="pa-0 pl-8 pr-8">
              <v-text-field label="Current Password" name="currentpassword" class="rounded-5 pa-0"
                v-model="currentpassword" validate-on-blur outlined dense required
                :rules="[rules.required]" :type="isPasswordCurShow ? 'input' : 'password'"
                :append-icon="isPasswordCurShow ? 'mdi-eye-off' : 'mdi-eye'" @click:append="showCurPassword">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="9" class="pa-0 pl-8 pr-8">
              <v-text-field label="New Password" name="newpassword" class="rounded-5 pa-0" v-model="newpassword"
                validate-on-blur outlined dense required :rules="[rules.required, rules.passwordRules]"
                :type="isPasswordNewShow ? 'input' : 'password'"
                :append-icon="isPasswordNewShow ? 'mdi-eye-off' : 'mdi-eye'" @click:append="showNewPassword">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="9" class="pa-0 pl-8 pr-8">
              <v-text-field label="Confirm Password" name="confirmpassword" class="rounded-5 pa-0"
                v-model="confirmpassword" validate-on-blur outlined dense required :rules="[
                  rules.required,
                  rules.passwordRules,
                  passwordConfirmationRule,
                ]" :type="isPasswordConShow ? 'input' : 'password'"
                :append-icon="isPasswordConShow ? 'mdi-eye-off' : 'mdi-eye'" @click:append="showConPassword">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="8" class="pl-8 pt-0 pb-5">
              <v-btn class="btn-style" outlined color="primarytext" elevation="1" @click="update">
                Update
              </v-btn>
              <v-btn class="ma-2 btn-style" outlined color="secondary" elevation="1" @click="cancel">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import EncryptUtility from '../utility/js/encrypt-utility';
import Utility from '../../shared/utility';
export default {
  name: 'ChangePassword',
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt('userID'),
      currentpassword: '',
      newpassword: '',
      confirmpassword: '',
      isPasswordCurShow: false,
      isPasswordNewShow: false,
      isPasswordConShow: false,
      rules: {
        required: (value) => !!value || 'Input field is required.',
        passwordRules: (value) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
          return pattern.test(value) || 'Password must be valid.';
        },
      },
    };
  },
  computed: {
    //Comparing New password with COnfirm password
    passwordConfirmationRule() {
      return () =>
        this.newpassword === this.confirmpassword || 'Passwords must match';
    },
  },
  methods: {
    //Showing passwords on click of eye
    showCurPassword() {
      this.isPasswordCurShow = !this.isPasswordCurShow;
    },
    showNewPassword() {
      this.isPasswordNewShow = !this.isPasswordNewShow;
    },
    showConPassword() {
      this.isPasswordConShow = !this.isPasswordConShow;
    },
    //End of password eye function

    //Updating password
    update() {
      if (!this.$refs.changePasswordForm.validate()) return false;
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let passwordModel = {
        UserId: this.userId,
        OldPassword: this.currentpassword,
        NewPassword: this.confirmpassword,
        IsUser: true
      };
      this.axios
        .post('ap/user_pwd_reset', passwordModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'success',
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
            this.$refs.changePasswordForm.reset();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Cancel button click event
    cancel() {
      this.$refs.changePasswordForm.reset();
    },
  },
};
</script>