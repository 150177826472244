<template>
  <div>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-10 pb-5">
        <v-card elevation="1" class="containerbox" height="80%">
          <breadcrumbComp mainPage="Settings" customSubPageUrl="user-profile" customSubPage="User Profile"
            :backSlash="backSlash" :customPage="customPage" hideFav="true" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" lg="6" class="px-10 pr-1 py-1">
        <v-card class="pa-0 ma-0 containerbox" height="100%">
          <v-card-text class="pa-0 ma-0">
            <UserDetails :pfirstName="this.user_info.FName" :plastName="this.user_info.LName"
              :pphone="this.user_info.Phone" :pemail="this.user_info.Email" @resetUser="getresetUser" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="6" class="px-10 pl-1 py-1">
        <v-card class="pa-0 ma-0 containerbox" height="100%">
          <v-card-text class="pa-0 ma-0">
            <ChangePassword />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" lg="6" class="px-10 pr-1 py-1">
        <v-card class="pa-0 ma-0 containerbox" height="100%">
          <v-card-text class="pa-0 ma-0">
            <ProfilePreferences :pThemeCode="this.user_info.ThemeCode" @resetUser="getresetUser" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="6" class="px-10 pl-1 py-1">
        <v-card class="pa-0 ma-0 containerbox" height="100%">
          <v-card-text class="pa-0 ma-0">
            <AccountHistory />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
  </div>
</template>
<script>
import UserDetails from './_user-details';
import ChangePassword from './_change-password';
import AccountHistory from './_account-history';
import ProfilePreferences from './_profile-preferences.vue';
import EncryptUtility from '../utility/js/encrypt-utility';
import Utility from '../../shared/utility';
import breadcrumbComp from '../common/breadcrumb-comp'
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt('userID'),
      user_info: [],
      backSlash: true,
      customPage: true,
      backSlashParent: false,
    };
  },
  mounted() {
    this.getUserDetails();
  },
  methods: {
    //Getting user details based on User ID
    getUserDetails() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.axios
        .get('ap/user_details?user_id=' + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            this.user_info = responseData;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          var Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Cancel Click event in child getting it in this parent
    getresetUser(value) {
      if (value == true) {
        this.getUserDetails();
      }
    },
  },
  components: {
    UserDetails,
    ChangePassword,
    AccountHistory,
    ProfilePreferences,
    breadcrumbComp,
  },
};
</script>