<template>
  <div>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-5 pt-5">
        <v-row>
          <v-col sm="12" md="12" lg="6" class="subtitle-1 secondary--text pa-5 pl-8">
            Profile Preferences
          </v-col>
          <v-col sm="12" md="12" lg="6" class="pa-5">
            <v-btn class="btn-style float-right ma-2 mr-6" outlined elevation="1" color="primarytext"
              @click="editProfileDetails()" :disabled="!disProfilePre">
              Edit
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="profilePreferenceForm">
          <v-row>
            <v-col sm="12" md="12" lg="9" class="pa-0 pl-8 pr-8">
              <v-autocomplete outlined class="rounded-5 pa-0" v-model="pThemeCode" :items="themes" item-text="name"
                item-value="value" label="Theme" required validate-on-blur dense :rules="required"
                :disabled="disProfilePre" @change="selectedTheme()">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="8" class="pl-8 pt-0 pb-5">
              <v-btn class="btn-style" outlined color="primarytext" elevation="1" @click="submit"
                :disabled="disProfilePre">
                Submit
              </v-btn>
              <v-btn class="ma-2 btn-style" outlined color="secondary" elevation="1" @click="cancel"
                :disabled="disProfilePre">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import EncryptUtility from '../utility/js/encrypt-utility';
import Utility from '../../shared/utility';
export default {
  name: 'ProfilePreferences',
  props: {
    pThemeCode: String,
  },
  data() {
    return {
      theme: '',
      disProfilePre: true,
      userId: EncryptUtility.localStorageDecrypt('userID'),
      themes: [
        { name: 'Dark', value: 'Dark' },
        { name: 'Light', value: 'Light' },
      ],
      required: [(v) => !!v || 'Input field is required.'],
    };
  },
  methods: {
    //Edit button click event
    editProfileDetails() {
      this.disProfilePre = false;
    },
    //Theme value after selection
    selectedTheme() {
      if (this.pThemeCode == 'Dark') {
        this.$vuetify.theme.dark = true;
      } else if (this.pThemeCode == 'Light') {
        this.$vuetify.theme.dark = false;
      }
    },
    //Submitting the theme value
    submit() {
      if (!this.$refs.profilePreferenceForm.validate()) return false;
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let themeModel = {
        UserId: this.userId,
        ThemeCode: this.pThemeCode,
      };
      this.axios
        .post('ap/user_update', themeModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'success',
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
            if (this.pThemeCode == 'Dark') {
              this.$vuetify.theme.dark = true;
              EncryptUtility.localStorageEncrypt(
                'theme',
                JSON.stringify(this.$vuetify.theme.dark),
              );
            } else if (this.pThemeCode == 'Light') {
              this.$vuetify.theme.dark = false;
              EncryptUtility.localStorageEncrypt(
                'theme',
                JSON.stringify(this.$vuetify.theme.dark),
              );
            }
            this.disProfilePre = true;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Cancel button click event
    cancel() {
      this.disProfilePre = true;
      var theme = EncryptUtility.localStorageDecrypt('theme');
      if (theme == 'false') {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
      this.$emit("resetUser", true);
      this.$refs.profilePreferenceForm.resetValidation();
    },
  },
};
</script>