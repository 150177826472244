<template>
  <div>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-5 pt-5 pb-10">
        <v-row>
          <v-col sm="12" md="12" lg="6" class="subtitle-1 secondary--text pa-5 pl-8">
            User Details
          </v-col>
          <v-col sm="12" md="12" lg="6" class="pa-5">
            <v-btn class="btn-style float-right ma-2 mr-6" outlined elevation="1" color="primarytext"
              @click="editDetails()" :disabled="!disUserdetails">
              Edit
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="userDetailsForm">
          <v-row>
            <v-col sm="12" md="12" lg="9" class="pa-0 pl-8 pr-8">
              <v-text-field label="First Name" name="firstname" tye="text" class="rounded-5 pa-0" placeholder="First Name"
                v-model="pfirstName" validate-on-blur outlined dense required :rules="nameRules"
                :disabled="disUserdetails">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="9" class="pa-0 pl-8 pr-8">
              <v-text-field label="Last Name" name="lastname" type="text" class="rounded-5 pa-0" placeholder="Last Name"
                v-model="plastName" validate-on-blur outlined dense required :rules="nameRules"
                :disabled="disUserdetails">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="9" class="pa-0 pl-8 pr-8">
              <VueTelInputVuetify name="phone" v-model="pphone" :onlyCountries="countriesList" outlined dense
                label="Phone" flat maxLen="25" mode="international" defaultCountry="US" validate-on-blur required
                :rules="phoneRules" :disabled="disUserdetails" @input="onUpdate" />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="9" class="pa-0 pl-8 pr-8">
              <v-text-field label="Email" name="email" type="email" class="rounded-5 pa-0" placeholder="Email"
                v-model="pemail" validate-on-blur outlined dense required :rules="emailRules" :disabled="disUserdetails">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="8" class="pl-8 pt-0 pb-5">
              <v-btn class="btn-style" outlined color="primarytext" elevation="1" @click="submit"
                :disabled="disUserdetails">
                Submit
              </v-btn>
              <v-btn class="ma-2 btn-style" outlined color="secondary" elevation="1" @click="cancel"
                :disabled="disUserdetails">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import EncryptUtility from '../utility/js/encrypt-utility';
import Utility from '../../shared/utility';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify';
export default {
  name: 'UserDetails',
  props: {
    pfirstName: String,
    plastName: String,
    pphone: String,
    pemail: String,
  },
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt('userID'),
      theme: JSON.parse(EncryptUtility.localStorageDecrypt('themeCode')),
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      disUserdetails: true,
      nameRules: [
        (v) => !!v || 'Input field is required.',
        (v) => /^[a-zA-Z]+$/.test(v) || 'Name must be valid.',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid.',
      ],
      phoneRules: [
        (v) => !!v || 'Input field is required.',
        (v) => /^\+[1-9]{1}[0-9]{7,11}$/.test(v.replace(/-|\s/g, "")) || 'Phone must be valid.',
      ],
      countriesList: ['IN', 'US', 'GB', 'CA'],
      phoneNumChange: null,
    };
  },
  methods: {
    //Editing user details button click
    editDetails() {
      this.disUserdetails = false;
    },
    //Country code selection and phone number formatting while uploading
    onUpdate() {
      this.phoneNumChange = this.pphone.replace(/-|\s/g, "");
    },
    //Submitting user details after updating
    submit() {
      if (!this.$refs.userDetailsForm.validate()) return false;
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let updateUserModel = {
        UserId: this.userId,
        FName: this.pfirstName,
        LName: this.plastName,
        Email: this.pemail,
        Phone: this.phoneNumChange,
        ThemeCode: this.theme,
      };
      this.axios
        .post('ap/user_update', updateUserModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'success',
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
            this.disUserdetails = true;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Cancel button click event
    cancel() {
      this.disUserdetails = true;
      this.$emit("resetUser", true);
      this.$refs.userDetailsForm.resetValidation();
    },
  },
  components: {
    VueTelInputVuetify,
  },
};
</script>