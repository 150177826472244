<template>
  <div>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-5 pt-5 pb-10">
        <v-row>
          <v-col sm="12" md="12" lg="12" class="subtitle-1 secondary--text pa-5 pl-8">
            Login History
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="12" lg="12" class="pa-0 pl-8 pr-10 pb-5">
            <v-data-table id="acchistory" v-model="acchistory" item-key="Activity" :headers="acchisheaders"
              :items="acchistoryitems" :items-per-page="5">
              <template v-slot:item="props">
                <tr>
                  <td>
                    {{ props.item.AppCode }}
                  </td>
                  <td v-if="props.item.Success">
                    <span class="successMsg">Success</span>
                  </td>
                  <td v-else>
                    <span class="failMsg">Fail</span>
                  </td>
                  <!-- <td>
                    {{ props.item.SystemInfo }}
                  </td> -->
                  <td>
                    {{ props.item.CreatedDate }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import EncryptUtility from '../utility/js/encrypt-utility';
import Utility from '../../shared/utility';
export default {
  name: 'AccountHistory',
  data() {
    return {
      acchistoryitems: [],
      userId: EncryptUtility.localStorageDecrypt('userID'),
      acchisheaders: [
        { text: 'App Code', align: 'start', value: 'AppCode' },
        { text: 'Status', value: 'Success' },
        { text: 'Created Date', value: 'CreatedDate' },
      ],
    };
  },
  mounted() {
    this.getUserHistory();
  },
  methods: {
    //Getting User History
    getUserHistory() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.email = EncryptUtility.localStorageDecrypt('email');
      this.axios
        .get(`ap/login_history?email=${this.email}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                CreatedDate: Utility.convertESTToLocal(obj.CreatedDate),
              };
            });
            this.acchistoryitems = newArr;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          var Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
  },
};
</script>
<style scoped>
.successMsg {
  background-color: #2ed8b6;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.failMsg {
  background-color: #ff5370;
  border-radius: 5px;
  padding: 5px;
  color: white;
}
</style>